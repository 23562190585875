import { useEffect, useState } from 'react';
import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import { ListOption, Select } from '@/components/shared/select';
import Icon from '@/components/Icon';
import { Policy, PolicyLink } from '@/models/document/policy';
import { Document, DocumentFieldType } from '@/models/document/document';
import { link } from '@/services/document/documentService';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import styles from './link.module.css';
import '@/theme.module.css';
import { Opportunity, OpportunityLink } from '@/models/document/opportunity';
import { formatTerm } from '../../../controllers/common/utils';

interface LinkProps {
  selectedDocumentList: Document[];
  open: boolean;
  closeDialog: any;
  linkedPolicyList: PolicyLink[];
  allPolicyList: Policy[];
  linkedOpportunityList: OpportunityLink[];
  allOpportunityList: Opportunity[];
  showAlertToast: any;
  deselectAll: () => void;
  refreshData: () => void;
  userId: string;
  userName: string;
  updateField: (
    documentId: string,
    field: DocumentFieldType,
    value: any
  ) => void;
}

export default function Link({
  selectedDocumentList,
  open,
  closeDialog,
  linkedPolicyList,
  allPolicyList,
  linkedOpportunityList,
  allOpportunityList,
  showAlertToast,
  deselectAll,
  refreshData,
  userId,
  userName,
  updateField,
}: LinkProps) {
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>(
    linkedPolicyList.map((policy: PolicyLink) => policy.policyId)
  );
  const [policyOptionList, setPolicyOptionList] = useState<ListOption[]>([]);
  const [selectedOpportunities, setSelectedOpportunities] = useState<string[]>(
    linkedOpportunityList.map(
      (opportunity: Opportunity) => opportunity.opportunityId
    )
  );
  const [opportunityOptionList, setOpportunityOptionList] = useState<
    ListOption[]
  >([]);
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      const mappedPolicyValues = allPolicyList?.map(
        (policyItem: Policy) =>
          ({
            label: `${policyItem.carrier.name} (${policyItem.policyNumber})`,
            subtext: `Term: ${formatTerm(
              policyItem.effectiveDate,
              policyItem.expirationDate
            )} | Status: ${policyItem.policyStatus}`,
            value: policyItem.policyId,
            groupByValue: new Date(policyItem.effectiveDate).getFullYear(),
            selected: selectedPolicies.includes(policyItem.policyId),
          } as ListOption)
      );
      setPolicyOptionList(mappedPolicyValues);

      const mappedOpportunityValues = allOpportunityList?.map(
        (opportunity: Opportunity) =>
          ({
            label: `${opportunity.name}`,
            value: opportunity.opportunityId,
            selected: selectedOpportunities.includes(opportunity.opportunityId),
          } as ListOption)
      );
      setOpportunityOptionList(mappedOpportunityValues);
    }
  }, [open]);

  const save = () => {
    setLoading(true);
    link(
      userId,
      userName,
      selectedDocumentList.map((document: Document) => document.id),
      selectedPolicies,
      getDeselectedPolicies(),
      selectedOpportunities,
      getDeselectedOpportunities(),
      authedFetch
    )
      .then((result: any) => {
        const policyListString = selectedPolicies
          .map((policyId: string) => {
            const policy = allPolicyList.find(
              (policy: Policy) => policy.policyId === policyId
            );
            return `"${policy?.policyNumber}"`;
          })
          .join(', ');
        const opportunityListString = selectedOpportunities
          .map((opportunityId: string) => {
            const opportunity = allOpportunityList.find(
              (opportunity: Opportunity) =>
                opportunity.opportunityId === opportunityId
            );
            return `"${opportunity?.name}"`;
          })
          .join(', ');

        const message = `File${
          selectedDocumentList.length > 1 ? 's' : ''
        } linked to: ${
          policyListString
            ? `Polic${
                selectedPolicies.length > 1 ? 'ies' : 'y'
              } - ${policyListString}`
            : ''
        }${policyListString && opportunityListString ? '& ' : ''}${
          opportunityListString
            ? `Opportunit${
                selectedOpportunities.length > 1 ? 'ies' : 'y'
              } - ${opportunityListString}`
            : ''
        }`;

        showAlertToast('success', message);
        resetThenClose();
        deselectAll();
        selectedDocumentList.forEach((document: Document) => {
          updateField(document.id, 'link', true);
        });
        refreshData();
      })
      .catch((error: any) => {
        showAlertToast('warning', `Error updating links`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePolicySelect = (clickedPolicy: ListOption) => {
    const selectedPolicyIds: string[] = [];

    const policyOptionListCopy = [...policyOptionList];
    policyOptionListCopy.forEach((policy: ListOption) => {
      if (policy.value === clickedPolicy.value) {
        policy.selected = !policy.selected;
      }
      if (policy.selected) {
        selectedPolicyIds.push(policy.value);
      }
    });
    setPolicyOptionList(policyOptionListCopy);
    setSelectedPolicies(selectedPolicyIds);
  };

  const getDeselectedPolicies = (): string[] => {
    const deselectedPolicyIdList: string[] = [];

    linkedPolicyList.forEach((linkedPolicy: PolicyLink) => {
      if (!selectedPolicies.includes(linkedPolicy.policyId)) {
        deselectedPolicyIdList.push(linkedPolicy.policyId);
      }
    });

    return deselectedPolicyIdList;
  };

  const getDeselectedOpportunities = (): string[] => {
    const deselectedOpportunityIdList: string[] = [];

    linkedOpportunityList.forEach((linkedOpportunity: Opportunity) => {
      if (!selectedOpportunities.includes(linkedOpportunity.opportunityId)) {
        deselectedOpportunityIdList.push(linkedOpportunity.opportunityId);
      }
    });

    return deselectedOpportunityIdList;
  };

  const handleOpportunitySelect = (clickedOpportunity: ListOption) => {
    const selectedOpportunityIds: string[] = [];

    const opportunityOptionListCopy = [...opportunityOptionList];
    opportunityOptionListCopy.forEach((opportunity: ListOption) => {
      if (opportunity.value === clickedOpportunity.value) {
        opportunity.selected = !opportunity.selected;
      }
      if (opportunity.selected) {
        selectedOpportunityIds.push(opportunity.value);
      }
    });
    setOpportunityOptionList(opportunityOptionListCopy);
    setSelectedOpportunities(selectedOpportunityIds);
  };

  const resetThenClose = () => {
    setSelectedPolicies(
      linkedPolicyList.map((policy: PolicyLink) => policy.policyId)
    );
    setPolicyOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    setSelectedOpportunities(
      linkedOpportunityList.map((opp: Opportunity) => opp.opportunityId)
    );
    setOpportunityOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    closeDialog();
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Link to...</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <Select
          label={'Policy #'}
          theme={'crm'}
          options={policyOptionList}
          placeholder={'Select an associated policy...'}
          updateValue={handlePolicySelect}
          multiple={true}
        />
        <Select
          label={'Opportunity'}
          theme={'crm'}
          options={opportunityOptionList}
          placeholder={'Select an associated opportunity...'}
          updateValue={handleOpportunitySelect}
          multiple={true}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={save}
          type={'primary'}
          theme={'crm'}
          loading={loading}
        >
          Save
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
