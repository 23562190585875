import { ReactNode } from 'react';
import ErrorBoundary from '../ErrorBoundary';
import { isReactNodeEmpty } from '@/services/lib/react';
interface Props {
  headingText?: string;
  children: ReactNode;
}

const SubheadingDivider: React.FC<Props> = ({ headingText, children }) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center gap-2.5">
        <span className="text-light-text-primary dark:text-dark-text-primary text-sm">
          {headingText ?? ''}
        </span>
        <span className="border-t border-light-borders-tertiary dark:border-dark-borders-tertiary flex-grow"></span>
      </div>
      <div className="flex flex-row flex-wrap gap-5">{children}</div>
    </div>
  );
};

const WrappedSubheadingDivider: React.FC<Props> = ({
  children,
  headingText,
}) => {
  if (isReactNodeEmpty(children)) {
    return null;
  }
  return (
    <ErrorBoundary
      errorContext={`Error in SubheadingDivider ${headingText} ${children?.toString()}`}
    >
      <SubheadingDivider headingText={headingText}>
        {children}
      </SubheadingDivider>
    </ErrorBoundary>
  );
};

export default WrappedSubheadingDivider;
