'use client';

import { useEffect, useState } from 'react';
import { Document, DocumentLinks } from '@/models/document/document';
import Checkbox from '../../shared/checkbox';
import Icon from '../../Icon';
import { Column, Table, TableTextInterface } from '../../shared/table';
import Button from '../../shared/button';
import {
  getDocumentBlob,
  getDocumentLink,
} from '@/services/document/documentService';
import { DocumentUrl } from '@/models/document/documentUrl';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import Tooltip from '../../shared/tooltip';
import { PolicyLink } from '@/models/document/policy';
import { OpportunityLink } from '@/models/document/opportunity';
import styles from './documentTable.module.css';
import '@/theme.module.css';

const botUser = 'Bot User';
const timeFormat = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  month: '2-digit',
  day: '2-digit',
  year: '2-digit',
});

const formatTerm = (effectiveDate: string, expirationDate: string): string => {
  if (!effectiveDate || !expirationDate) {
    return '-';
  }

  return (
    timeFormat.format(new Date(effectiveDate)) +
    ' - ' +
    timeFormat.format(new Date(expirationDate))
  );
};

const mapOpportunityList = (opportunity: OpportunityLink) =>
  ` •  ${opportunity.name}`;
const mapPolicyList = (policy: PolicyLink) =>
  ` •  ${policy.policyNumber} (${formatTerm(
    policy.effectiveDate,
    policy.expirationDate
  )})`;

const getLinksHover = (links: DocumentLinks): string => {
  const opportunityList = links?.opportunity ?? [];
  const policyList = links?.policy ?? [];

  let output = ``;

  if (opportunityList?.length) {
    output += `Associated opportunities:\n`;
    output += opportunityList.map(mapOpportunityList).join('\n');
  }

  if (opportunityList?.length && policyList?.length) {
    output += '\n';
  }

  if (policyList?.length) {
    output += `Associated policies:\n`;
    output += policyList.map(mapPolicyList).join('\n');
  }

  return output;
};

const linkCellContent = (row: Document, item: string) => {
  const links = row?.links;
  const linkCount =
    (links?.opportunity?.length ?? 0) + (links?.policy?.length ?? 0);

  if (row.loading) {
    return <span className={styles.loadingText}>Loading...</span>;
  } else if (links && linkCount > 1) {
    return (
      <Tooltip message={getLinksHover(links)} position={'auto'} theme={'crm'}>
        <span className={styles.multiplePolicies}>Multiple ({linkCount})</span>
      </Tooltip>
    );
  } else if (row.links?.opportunity?.[0]) {
    const opportunity = row.links.opportunity[0];

    return (
      <div className="flex flex-col gap-0">
        <div>Opportunity</div>
        <small>
          <em>{opportunity?.name}</em>
        </small>
      </div>
    );
  } else if (row?.links?.policy?.[0]) {
    const policy = row.links.policy[0];

    return (
      <div className="flex flex-col gap-0">
        <div>Policy #: {policy.policyNumber}</div>
        <small>
          <em>
            Term: {formatTerm(policy.effectiveDate, policy.expirationDate)}
          </em>
        </small>
      </div>
    );
  } else {
    return '-';
  }
};

interface DocumentTableInterface {
  documents: Document[] | undefined;
  setSelectedDocuments: any;
  tableText: TableTextInterface;
  showAlertToast: (type: string, text: string) => void;
  options:
    | {
        columns?: {
          link?: {
            hide?: boolean;
          };
          category?: {
            hide?: boolean;
          };
          createdAt?: {
            hide?: boolean;
          };
          createdBy?: {
            hide?: boolean;
          };
          note?: {
            hide?: boolean;
          };
        };
      }
    | undefined;
}

export default function DocumentTable({
  documents,
  setSelectedDocuments,
  tableText,
  showAlertToast,
  options,
}: DocumentTableInterface) {
  const showCheckboxes = !!setSelectedDocuments;
  const [documentList, setDocumentList] = useState<Document[] | undefined>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    if (documents) {
      setDocumentList(documents);
    }
  }, [documents]);

  useEffect(() => {
    if (documentList && setSelectedDocuments) {
      setSelectedDocuments(
        documentList.filter((document: Document) => !!document.selected)
      );
    }
  }, [documentList, setSelectedDocuments]);

  const headerContent = () =>
    showCheckboxes && (
      <Checkbox
        checked={selectAll}
        onChange={() => toggleSelectAll()}
        theme={'crm'}
      />
    );
  const headerCellContent = (row: Document, item: string) =>
    showCheckboxes && (
      <Checkbox
        checked={!!row.selected || false}
        onChange={() => toggleSelection(row.id, !row.selected)}
        theme={'crm'}
      />
    );

  const nameCellContent = (row: Document, item: string) => (
    <Button type={'link'} callback={() => viewDocument(row)} theme={'crm'}>
      {item}
    </Button>
  );

  const addedByCellContent = (row: Document, item: string) =>
    row.createdBy?.name === botUser ? (
      <div title={botUser}>
        <Icon type={'sparkle'} color={'black'} viewbox={'0 -4 24 24'} />
      </div>
    ) : (
      row.createdBy?.name
    );

  const addedBySortFunction = (
    a: any,
    b: any,
    sortField: string,
    order: string,
    defaultSort: (a: any, b: any) => number
  ) => {
    if (a[sortField] === botUser) {
      return order === 'asc' ? -1 : 1;
    } else if (b[sortField] === botUser) {
      return order === 'asc' ? 1 : -1;
    } else {
      return defaultSort(a, b);
    }
  };

  const toggleSelection = (documentId: string, isChecked = false) => {
    setDocumentList((prevState: any) => {
      const documentListCopy = [...prevState];
      documentListCopy.forEach((document: Document) => {
        if (document.id === documentId) {
          document.selected = isChecked;
        }
      });
      return documentListCopy;
    });
  };
  useEffect(() => {
    const allSelected = documentList?.length
      ? documentList.every((doc) => doc.selected)
      : false;
    if (allSelected !== selectAll) {
      setSelectAll(allSelected);
    }
  }, [documentList]);

  const toggleSelectAll = () => {
    const newSelectAllState = !selectAll;
    setDocumentList((prevState: any) => {
      const documentListCopy = [...(prevState ? prevState : [])];
      documentListCopy.forEach((document: Document) => {
        document.selected = newSelectAllState;
      });
      return documentListCopy;
    });

    setSelectAll(newSelectAllState);
  };

  const viewDocument = async (document: Document) => {
    const openLink = (url: string, filename: string) => {
      const link = window.document.createElement('a');
      link.href = url;
      link.download = filename;
      link.target = '_blank';
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
    };

    try {
      if (document.fileUrl) {
        //get link to file in blob storage
        const documentLink: DocumentUrl = await getDocumentLink(
          document.id,
          authedFetch
        );
        if (documentLink) {
          openLink(documentLink.url, document.fileOriginalName);
        }
      } else {
        //get file from ams
        const blob = await getDocumentBlob(document.id, authedFetch);
        const url = window.URL.createObjectURL(blob);
        openLink(url, document.fileOriginalName);
      }
    } catch (err) {
      showAlertToast('warning', 'Error viewing document.');
    }
  };

  const columns: Column[] = [
    {
      label: '',
      accessor: '',
      headerClasses: `${styles.textCenter}`,
      headerContent: headerContent,
      cellClasses: `${styles.textCenter}`,
      cellContent: headerCellContent,
      sortable: false,
    },
    {
      label: 'Name',
      accessor: 'fileOriginalName',
      cellClasses: `${styles.noWrap}`,
      cellContent: nameCellContent,
      sortable: true,
    },
  ];

  if (!options?.columns?.link?.hide) {
    columns.push({
      label: 'Link',
      accessor: 'link',
      sortable: true,
      cellClasses: `${styles.noWrap}`,
      cellContent: linkCellContent,
    });
  }

  if (!options?.columns?.category?.hide) {
    columns.push({
      label: 'Category',
      accessor: 'documentType',
      cellClasses: `${styles.noWrap}`,
      sortable: true,
    });
  }

  if (!options?.columns?.createdAt?.hide) {
    columns.push({
      label: 'Added to Fusion',
      accessor: 'createdAt',
      cellClasses: `${styles.noWrap}`,
      cellContent: (row: Document, item: string) =>
        timeFormat.format(new Date(item)),
      sortable: true,
      sortByOrder: 'desc',
    });
  }

  if (!options?.columns?.createdBy?.hide) {
    columns.push({
      label: 'Added by',
      accessor: 'createdBy',
      cellClasses: `${styles.noWrap}`,
      cellContent: addedByCellContent,
      sortable: true,
      sortFunction: addedBySortFunction,
    });
  }

  if (!options?.columns?.note?.hide) {
    columns.push({
      label: 'Note',
      accessor: 'notes',
      cellContent: (_row: Document, item: string) => item ?? '-',
      sortable: true,
    });
  }

  return (
    <div className={styles.tableContainer}>
      <Table
        classes={styles.table}
        theme={'crm'}
        columns={columns}
        data={documentList}
        tableText={tableText}
      />
    </div>
  );
}
