import { useEffect, useMemo, useState } from 'react';
import { sendEmail } from '@/services/forms/formsService';
import { FormOrigin, FusionForm } from '@/models/forms/form';
import { ListOption } from '@/components/shared/select';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import EmailComponent from './EmailComponent';
import { PolicyKeySet } from '../form/types';
import { EmailFormRequest } from '@/models/forms/emailRequest';
import { useFormContext } from '../form/FormContext';
import { Contact } from '@/models/forms/contact';
import { useMsal } from '@azure/msal-react';

interface Props {
  selectedFormList: FusionForm[];
  selectedPolicyKeys: PolicyKeySet[];
  associatedContactList: Contact[];
  open: boolean;
  closeDialog(): void;
  showAlertToast(type: string, text: string): void;
  username: string | undefined;
}

const EmailController: React.FC<Props> = ({
  selectedFormList,
  selectedPolicyKeys,
  associatedContactList,
  open,
  closeDialog,
  showAlertToast,
  username,
}) => {
  const { customer, deselectAll } = useFormContext();
  const { instance } = useMsal();
  const [selectedOption, setSelectedOption] = useState<string>('customer');
  const [emailOptionList, setEmailOptionList] = useState<ListOption[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>(
    associatedContactList && associatedContactList.length
      ? associatedContactList[0].emailAddress
      : ''
  );
  const [personalMessage, setPersonalMessage] = useState<string>('');
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);
  const displayName = useMemo(() => {
    let displayName = customer?.firstName || customer?.businessName;
    if (selectedOption !== 'customer' || !displayName) {
      //alt email or name not found
      displayName = 'Valued Brightway Customer';
    }
    return displayName;
  }, [customer]);
  const origin: FormOrigin = useMemo(() => {
    const bwForm = selectedPolicyKeys.find(
      (policy: PolicyKeySet) =>
        policy.formKey === 'customer-undeliverable-mail-form' ||
        policy.formName?.toLowerCase().includes('underwriting memo')
    );
    return bwForm ? 'brightway' : 'user';
  }, [selectedPolicyKeys]);

  useEffect(() => {
    if (!(customer && associatedContactList)) {
      return;
    }
    const mappedValues = [
      ...new Set<string>(
        associatedContactList.map((contact: Contact) => contact.emailAddress)
      ),
    ].map(
      (emailAddress: string) =>
        ({
          label: emailAddress,
        } as ListOption)
    );
    if (associatedContactList.length === 1) {
      mappedValues[0].selected = true;
      setSelectedEmail(mappedValues[0].label);
    }
    setEmailOptionList(mappedValues);
  }, [associatedContactList, customer]);

  const handleEmailSelect = (clickedEmail: ListOption) => {
    setSelectedEmail(clickedEmail.label);

    const emailOptionListCopy = [...emailOptionList];
    emailOptionListCopy.forEach((email: ListOption) => {
      if (email.label === clickedEmail.label) {
        email.selected = true;
      } else {
        email.selected = false;
      }
    });
    setEmailOptionList(emailOptionListCopy);
  };

  const handlePersonalMessageChange = (value: string) => {
    setPersonalMessage(value);
  };

  const handleSendEmail = () => {
    // we can collect an event here if we change the args in the prototype // -> https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Building_blocks/Event_bubbling
    // does event fire? what element originated it? *hey
    // console.log(arguments); // -> https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/arguments

    if (!customer) {
      throw Error('customer does not exist');
    }

    if (!selectedEmail) {
      throw Error('email address does not exist');
    }

    if (!selectedFormList.length && !selectedPolicyKeys.length) {
      //Fixed the issue here - the policy keys weren't being checked, so it was returning before.
      throw Error('no forms selected');
    }

    const emailFormRequests: EmailFormRequest[] = [];

    selectedFormList.forEach((form) => {
      emailFormRequests.push({
        key: form.key,
        name: form.name,
        policyId: undefined,
        origin: form.metadata.origin,
      });
    });

    selectedPolicyKeys.forEach((policyKeySet) => {
      emailFormRequests.push({
        key: policyKeySet.formKey,
        name: policyKeySet.formName ?? 'unknown',
        policyId: policyKeySet.policyKey,
        origin: policyKeySet.origin,
      });
    });

    setLoading(true);
    sendEmail(
      displayName,
      customer.customerId,
      selectedEmail,
      emailFormRequests,
      customer.accountId,
      username,
      personalMessage,
      authedFetch
    )
      .then((result) => {
        showAlertToast(
          'success',
          `Form${
            selectedFormList.length > 1 ? 's' : ''
          } sent to "${selectedEmail}"`
        );
        resetThenClose();
        deselectAll();
      })
      .catch((error) => {
        showAlertToast(
          'warning',
          `Error sending form${selectedFormList.length > 1 ? 's' : ''}`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetThenClose = () => {
    setSelectedOption('customer');
    setSelectedEmail(
      associatedContactList && associatedContactList.length === 1
        ? associatedContactList[0].emailAddress
        : ''
    );
    setPersonalMessage('');

    const emailOptionListCopy = [...emailOptionList];
    emailOptionListCopy.forEach(
      (email: ListOption) => (email.selected = false)
    );
    if (emailOptionListCopy.length === 1) {
      emailOptionListCopy[0].selected = true;
    }
    setEmailOptionList(emailOptionListCopy);

    closeDialog();
  };

  return (
    <EmailComponent
      resetThenClose={resetThenClose}
      handleEmailSelect={handleEmailSelect}
      handlePersonalMessageChange={handlePersonalMessageChange}
      handleSendEmail={handleSendEmail}
      emailOptionList={emailOptionList}
      associatedContactList={associatedContactList}
      selectedEmail={selectedEmail}
      selectedPolicyKeys={selectedPolicyKeys}
      personalMessage={personalMessage}
      displayName={displayName}
      loading={loading}
      open={open}
      origin={origin}
    />
  );
};

export default EmailController;
