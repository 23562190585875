import type { FC } from 'react';
import type { FormSection } from '@/services/forms/models/formTypes';
import { useFieldArray } from 'react-hook-form';
import SubheadingDivider from '../../SubheadingDivider';
import FieldMapper from '../FieldMapper';

type Field = {
  display: string;
  page: string;
};

type DynamicSectionProps = {
  section: FormSection;
  sectionDefaults: Record<string, unknown>;
  searchResults?: Field[] | null;
};

const DynamicSection: FC<DynamicSectionProps> = ({ section, sectionDefaults, searchResults }) => {
  const { fields, append, remove } = useFieldArray({ name: section.id });

  const handleClickAppend = () => {
    append(sectionDefaults);
  };

  return (
    <SubheadingDivider headingText={section.display}>
      <div>
        {fields.map((field, index) => {
          return (
            <div key={field.id}>
              {section.elements.map((entityField) => {
                entityField.highlight =
                  searchResults?.some((result: Field) => entityField.display === result.display) ||
                  null;

                return <FieldMapper field={entityField} key={entityField.name} />;
              })}
              <button
                className="flex pb-[4px] gap-1.25 items-end text-light-text-error"
                type="button"
                onClick={() => remove(index)}
              >
                <span className="material-symbols-outlined font18 text-light-text-error dark:text-dark-text-error">
                  {'delete'}
                </span>
                <span>Delete</span>
              </button>
            </div>
          );
        })}
      </div>
      <div className="flex justify-end">
        <button className="flex items-center gap-1.25" type="button" onClick={handleClickAppend}>
          <span className="material-symbols-outlined font18 text-light-text-secondary dark:text-dark-text-secondary">
            {'add'}
          </span>
          <span>{`Add ${section.display}`}</span>
        </button>
      </div>
    </SubheadingDivider>
  );
};

export { DynamicSection };
