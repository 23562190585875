import type { FC } from 'react';
import { useState } from 'react';
import { useFormContext, FieldError, useFormState, get } from 'react-hook-form';
import { Calendar } from '@/components/ui/calendar';
import { Label } from '@/components/ui/label';
import { useInputMask } from '@/controllers/common/hooks/utility';
import { mask } from '../inputMasks';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import type { FormField } from '@/services/forms/models/formTypes';

type DatePickerProps = {
  field: FormField;
};

const DatePicker: FC<DatePickerProps> = ({ field }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { setValue, watch, trigger, register } = useFormContext();

  const { id, name, display, disabled, syncWith, highlight } = field;
  const selectedDate = watch(name);
  const { errors } = useFormState();
  const error: FieldError = get(errors, name);

  const isValidDate = (date: any) =>
    date instanceof Date && !isNaN(date.getTime());

  const validateDate = (value: any) => {
    if (!value) {
      return true;
    }
    const date = new Date(value);
    return isValidDate(date) || 'Please enter a valid date.';
  };

  const validation = {
    ...field.validation,
    validate: validateDate,
  };
  const { inputRef } = useInputMask({
    mask,
    register,
    value: selectedDate,
    setValue,
    name,
    validation,
    syncWith,
  });

  const getDefaultMonth = () => {
    const date = new Date(selectedDate);
    if (isValidDate(date)) {
      return date;
    }
    return new Date();
  };

  const handleDateChange = (date: Date | undefined) => {
    if (date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear());
      const formattedDateString = `${month}/${day}/${year}`;
      setValue(name, formattedDateString, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      if (syncWith) {
        setValue(syncWith.fieldName, formattedDateString, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    }
    setIsCalendarOpen(false);
  };

  const handleOnBlur = () => {
    trigger(name);
  };

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      {display && (
        <Label htmlFor={id || name}>
          <span className={highlight ? 'bg-yellow-200 px-2' : ''}>
            {display}
          </span>
        </Label>
      )}
      <div className="flex justify-between items-center gap-2.5 py-1.5 px-3 rounded-[4px] bg-[#F5F5F5] focus-within:outline-none focus-within:ring-2 disabled:cursor-not-allowed disabled:opacity-50">
        <input
          className="text-sm bg-[#F5F5F5] focus-visible:outline-none grow"
          id={id || name}
          type="text"
          data-testid={id || name}
          placeholder="MM/DD/YYYY"
          ref={inputRef}
          onBlur={handleOnBlur}
          disabled={disabled}
        />
        <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
          <PopoverTrigger asChild>
            <button className="h-[20px]">
              <span className="material-symbols-outlined font18 dark:text-dark-text-primary text-[20px]">
                calendar_month
              </span>
            </button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0 bg-[#FFFFFF]" align="end">
            <Calendar
              mode="single"
              selected={new Date(selectedDate)}
              onSelect={handleDateChange}
              autoFocus
              defaultMonth={getDefaultMonth()}
              disabled={disabled}
            />
          </PopoverContent>
        </Popover>
      </div>
      {error && (
        <p className="text-sm text-light-text-error">{error.message}</p>
      )}
    </div>
  );
};

export { DatePicker };
