import { Policy, PolicyLink } from '@/models/document/policy';
import { Customer } from '@/models/document/customer';
import { Document } from '@/models/document/document';
import { DocumentLink } from '@/models/document/documentLink';
import { EmailResponse } from '@/models/document/emailResponse';
import { UploadData } from '@/models/document/uploadData';
import { UploadLink } from '@/models/document/uploadLink';
import { DocumentUrl } from '@/models/document/documentUrl';
import { Contact } from '@/models/document/contact';
import { OpportunityLink } from '@/models/document/opportunity';
import { RequestError, request } from '@/services/common/utils';

const LINK_TABLE_TYPES = {
  policy: 'Policy',
  customer: 'Customer',
  account: 'Account',
  opportunity: 'Opportunity',
};

const LINK_SYSTEM_TYPES = {
  ams360: 'AMS360',
  dynamics: 'Dynamics',
};

export const getAccountDetails = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Customer> => {
  const url = `/apim/customer/v1/customers/${accountId}`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json().then((result) => {
    return {
      emailAddressList: [result.email, result.email2].filter(
        (email) => !!email
      ),
      ...result,
    };
  });
};

export const getAssociatedContactList = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Contact[]> => {
  const url = `/apim/customer/v1/customers/${accountId}/associated-contacts`;
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getPolicyList = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Policy[]> => {
  const url = `/apim/policy/v1/customers/${accountId}/policies?source=dynamics`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const buildPolicyLink = (document: Document) =>
  ({
    policyId: document.policyId,
    policyNumber: document.policyNumber,
    effectiveDate: document.effectiveDate,
    expirationDate: document.expirationDate,
  } as PolicyLink);
export const buildOpportunityLink = (document: Document) =>
  ({
    opportunityId: document.opportunityId,
    name: document.name,
    parentAccountId: document.parentAccountId,
  } as OpportunityLink);

export const consolidateDocs = (documents: Document[]) => {
  // merge any duplicates based on the document id
  // since a document can be linked to multiple things, there can be duplicates in the response
  const consolidatedDocs: Document[] = [];

  documents.forEach((document) => {
    const matchIndex = consolidatedDocs.findIndex(
      (searchDoc: Document) => searchDoc.id === document.id
    );
    if (matchIndex > -1) {
      const linkTable = document.linkTable.toLowerCase();

      if (
        linkTable === 'policy' &&
        consolidatedDocs[matchIndex].links?.policy
      ) {
        const policyLink = buildPolicyLink(document);
        consolidatedDocs[matchIndex].links?.policy?.push(policyLink);
      }

      if (
        document.linkTable.toLowerCase() === 'opportunity' &&
        consolidatedDocs[matchIndex].links?.opportunity
      ) {
        const opportunityLink = buildOpportunityLink(document);
        consolidatedDocs[matchIndex].links?.opportunity?.push(opportunityLink);
      }
    } else {
      const newConsolidatedDoc = { ...document };
      const newConsolidatedDocLinks = {
        opportunity: [] as OpportunityLink[],
        policy: [] as PolicyLink[],
      };

      if (document?.linkTable?.toLowerCase() === 'policy') {
        const policyLink = buildPolicyLink(document);
        newConsolidatedDocLinks.policy.push(policyLink);
      }

      if (document?.linkTable?.toLowerCase() === 'opportunity') {
        const opportunityLink = buildOpportunityLink(document);
        newConsolidatedDocLinks.opportunity?.push(opportunityLink);
      }
      newConsolidatedDoc.links = newConsolidatedDocLinks;
      consolidatedDocs.push(newConsolidatedDoc);
    }
  });

  return consolidatedDocs;
};

export const getDocuments = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Document[]> => {
  const url = `/apim/documents/v1/accounts/${accountId}/documents`;
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }

  const documents = await response.json();
  const consolidatedDocs: Document[] = consolidateDocs(documents);

  return consolidatedDocs;
};

export const getDocumentLink = async (
  documentId: string,
  authedFetch: typeof fetch
): Promise<DocumentUrl> => {
  const url = `/apim/documents/v1/documents/${documentId}/link`;

  const requestOptions: RequestInit = {
    method: 'GET',
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }

  return await response.json();
};

export const getDocumentBlob = async (
  documentId: string,
  authedFetch: typeof fetch
): Promise<Blob> => {
  const url = `/apim/documents/v1/documents/${documentId}`;

  const requestOptions: RequestInit = {
    method: 'GET',
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }

  return await response.blob();
};

export const addFileToFusion = async (
  data: UploadData,
  authedFetch: typeof fetch
): Promise<string> => {
  // return await Promise.resolve(formData.get('fileName'));
  const url = `/apim/documents/v1/upload`;

  const uploadData = {
    fileName: data.fileName,
    documentType: data.category,
    user: {
      id: data.userId,
      name: data.userName,
    },
    notes: data.notes,
    links: [] as UploadLink[],
  };

  data.policyList &&
    data.policyList.forEach((policyId: string) =>
      uploadData.links.push({
        linkId: policyId,
        linkTable: 'policy',
        linkSystem: 'dynamics',
      })
    );

  data.opportunityList.forEach((oppId: string) =>
    uploadData.links.push({
      linkId: oppId,
      linkTable: 'opportunity',
      linkSystem: 'dynamics',
    })
  );

  // all files are associated with the account
  uploadData.links.push({
    linkId: data.accountId,
    linkTable: 'account',
    linkSystem: 'dynamics',
  });

  const formData = new FormData();
  formData.append('file', data.file);
  formData.append('fileData', JSON.stringify(uploadData));

  const requestOptions: RequestInit = {
    method: 'POST',
    body: formData,
  };
  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }

  return data.fileName;
};

export const sendEmail = async (
  name: string,
  customerId: string,
  emailAddress: string,
  documentIdList: string[],
  personalMessage: string,
  authedFetch: typeof fetch
): Promise<EmailResponse[]> => {
  const url = `/apim/documents/v1/email/`;

  const jsonBody = JSON.stringify({
    name,
    customerId,
    emailAddresses: [emailAddress],
    documentIds: documentIdList,
    personalMessage,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const saveNote = async (
  selectedDocumentList: Document[],
  notes: string,
  authedFetch: typeof fetch
): Promise<Document[]> => {
  const url = `/apim/documents/v1/documents/`;

  const jsonBody = JSON.stringify(
    selectedDocumentList.map((document) => ({ id: document.id, notes: notes }))
  );

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const saveName = async (
  selectedDocument: Document,
  updatedValue: string,
  authedFetch: typeof fetch
): Promise<Document> => {
  const url = `/apim/documents/v1/documents/${selectedDocument.id}`;

  const jsonBody = JSON.stringify({
    fileOriginalName: updatedValue,
  });

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const saveCategory = async (
  selectedDocumentList: Document[],
  newCategory: string,
  authedFetch: typeof fetch
): Promise<Document[]> => {
  const url = `/apim/documents/v1/documents/`;

  const jsonBody = JSON.stringify(
    selectedDocumentList.map((document) => ({
      id: document.id,
      documentType: newCategory,
    }))
  );

  const requestOptions: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getCategoryList = async (
  authedFetch: typeof fetch
): Promise<string[]> => {
  const url = `/apim/documents/v1/documents/categories`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const link = async (
  userId: string,
  userName: string,
  selectedDocumentIdList: string[],
  selectedPolicyIdList: string[],
  deselectedPolicyIdList: string[],
  selectedOpportunityIdList: string[],
  deselectedOpportunityIdList: string[],
  authedFetch: typeof fetch
): Promise<Document[]> => {
  const url = `/apim/documents/v1/documents/link`;

  const addLinkRequestList: DocumentLink[] = [];

  selectedDocumentIdList.forEach((documentId: string) => {
    selectedPolicyIdList.forEach((policyId: string) => {
      addLinkRequestList.push({
        documentId: documentId,
        linkId: policyId,
        linkTable: LINK_TABLE_TYPES.policy,
        createdBy: {
          id: userId,
          name: userName,
        },
        isDeleted: false,
        linkSystem: LINK_SYSTEM_TYPES.dynamics,
      });
    });

    selectedOpportunityIdList.forEach((opportunityId: string) => {
      addLinkRequestList.push({
        documentId: documentId,
        linkId: opportunityId,
        linkTable: LINK_TABLE_TYPES.opportunity,
        createdBy: {
          id: userId,
          name: userName,
        },
        isDeleted: false,
        linkSystem: LINK_SYSTEM_TYPES.dynamics,
      });
    });
  });

  const removeLinkRequestList: DocumentLink[] = [];

  selectedDocumentIdList.forEach((documentId: string) => {
    deselectedPolicyIdList.forEach((policyId: string) => {
      removeLinkRequestList.push({
        documentId: documentId,
        linkId: policyId,
        linkTable: LINK_TABLE_TYPES.policy,
        createdBy: {
          id: userId,
          name: userName,
        },
        isDeleted: true,
        linkSystem: LINK_SYSTEM_TYPES.dynamics,
      });
    });
    deselectedOpportunityIdList.forEach((opportunityId: string) => {
      removeLinkRequestList.push({
        documentId: documentId,
        linkId: opportunityId,
        linkTable: LINK_TABLE_TYPES.opportunity,
        createdBy: {
          id: userId,
          name: userName,
        },
        isDeleted: true,
        linkSystem: LINK_SYSTEM_TYPES.dynamics,
      });
    });
  });

  const jsonBody = JSON.stringify({
    addLinks: addLinkRequestList,
    removeLinks: removeLinkRequestList,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};
