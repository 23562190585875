import Icon from '@/components/Icon';
import { FusionForm } from '../../../../models/forms/form';
import { Policy } from '../../../../models/forms/policy';
import { useFormContext } from '../form/FormContext';
import styles from './FormBuilder.module.css';
import TextField from '@/components/shared/textField';
import { ListOption, Select } from '@/components/shared/select';
import Button from '@/components/shared/button';
import { useEffect, useState } from 'react';
import { UwMemoAllowedRowType } from '../form/types';

interface FormRow {
  text: string;
  type: UwMemoAllowedRowType | '';
  order: number;
  id: string;
  typeList: ListOption[];
}

interface Props {
  policy: Policy;
  isSelected: boolean;
}

const FormBuilder: React.FC<Props> = ({ policy, isSelected }) => {
  const getId = () => {
    return Date.now().toString();
  };

  const { uwMemo, setUwMemo } = useFormContext();
  const [dragId, setDragId] = useState<string>('');
  const types = [
    { label: 'Text field', value: 'text', selected: false },
    {
      label: 'Date picker',
      value: 'date',
      selected: false,
    },
    {
      label: 'Upload field',
      value: 'file',
      selected: false,
    },
  ];
  const [newFormList, setNewFormList] = useState<FormRow[]>([
    { text: '', type: '', order: 0, id: getId(), typeList: [...types] },
  ]);

  useEffect(() => {
    if (policy && !isSelected) {
      //remove entry from uw memo object
      const updatedUwMemo = { ...uwMemo };
      delete updatedUwMemo[policy.amsPolicyId];
      setUwMemo(updatedUwMemo);

      //reset list of questions in component
      setNewFormList([
        { text: '', type: '', order: 0, id: getId(), typeList: [...types] },
      ]);
    }
  }, [policy, isSelected]);

  useEffect(() => {
    if (policy && isSelected) {
      const updatedUwMemo = { ...uwMemo };
      updatedUwMemo[policy.amsPolicyId] = newFormList.map(
        (formRow: FormRow) => {
          return {
            text: formRow.text,
            type: formRow.type as UwMemoAllowedRowType,
            order: formRow.order,
          };
        }
      );
      setUwMemo(updatedUwMemo);
    }
  }, [policy, isSelected, newFormList]);

  const addRow = () => {
    const formListCopy = [...newFormList];
    formListCopy.push({
      text: '',
      type: '',
      order: newFormList.length,
      id: getId(),
      typeList: [...types],
    });
    setNewFormList(formListCopy);
  };

  const removeRow = (index: number) => {
    if (newFormList.length < 2) {
      return; //can't delete if only one row
    }

    const formListCopy = [...newFormList];
    formListCopy.splice(index, 1);
    setNewFormList(formListCopy);
  };

  const handleTextChange = (index: number, value: string) => {
    const formListCopy = [...newFormList];
    formListCopy[index].text = value;
    setNewFormList(formListCopy);
  };

  const handleTypeChange = (index: number, listElement: ListOption) => {
    setNewFormList((prevState: FormRow[]) => {
      const formListCopy = [...prevState];
      formListCopy[index].type = listElement.value;
      formListCopy[index].typeList = [...types].map((type: ListOption) => {
        if (type.value === listElement.value) {
          type.selected = true;
        }
        return type;
      });
      return formListCopy;
    });
  };

  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
    ev.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (ev: any) => {
    ev.preventDefault();
    const dragRow = newFormList.find(
      (formRow: FormRow) => formRow.id === dragId
    );
    const dropRow = newFormList.find(
      (formRow: FormRow) => formRow.id === ev.currentTarget.id
    );

    if (!dragRow || !dropRow) {
      return;
    }

    const dragRowOrder = dragRow.order;
    const dropRowOrder = dropRow.order;

    const formListCopy = newFormList.map((formRow: FormRow) => {
      if (formRow.id === dragId) {
        formRow.order = dropRowOrder;
      }
      if (formRow.id === ev.currentTarget.id) {
        formRow.order = dragRowOrder;
      }
      return formRow;
    });
    setNewFormList(formListCopy);
  };

  const handleDragEnd = (ev: any) => {
    setDragId('');
  };

  const handleDrop = (ev: any) => {
    setDragId('');
  };

  if (!isSelected) {
    return;
  }

  return (
    <>
      <ul>
        {newFormList
          .sort((a: FormRow, b: FormRow) => a.order - b.order)
          .map((formRow: FormRow, index: number) => (
            <li
              className={`${styles.questionRow} ${
                formRow.id === dragId ? styles.drag : null
              }`}
              id={formRow.id}
              draggable={true}
              onDragOver={handleDragOver}
              onDragStart={handleDrag}
              onDragEnd={handleDragEnd}
              onDrop={handleDrop}
            >
              <div className={styles.reorder}>
                <Icon type={'density_small'} color={'grey-300'} size={20} />
              </div>
              <TextField
                value={formRow.text}
                onChange={handleTextChange.bind(this, index)}
                placeholder={'Type your question here...'}
                theme={'crm'}
                spellcheck={true}
              />
              <div className={styles.dropdownContainer}>
                <Select
                  label={''}
                  options={formRow.typeList}
                  updateValue={handleTypeChange.bind(this, index)}
                  placeholder={'Select question type'}
                  theme={'crm'}
                />
              </div>
              <div
                className={styles.deleteContainer}
                onClick={() => removeRow(index)}
              >
                {newFormList.length > 1 && (
                  <Icon type={'delete'} color={'grey-300'} size={20} />
                )}
              </div>
            </li>
          ))}
      </ul>
      <div>
        <Button type={'link'} callback={addRow} theme={'crm'}>
          + Add question
        </Button>
      </div>
    </>
  );
};

export default FormBuilder;
