import { useParams, useSearchParams } from 'react-router-dom';
import {
  Copy,
  Form,
  FormContent,
  APIManager,
  LoadingSpinner,
  useAsync,
} from '@bwinsurance/shared-components';
import '@bwinsurance/shared-components/dist/styles.css';
import { useCallback, useEffect } from 'react';
import { CUSTOMER_ID_SEARCH_PARAM, POLICY_ID_SEARCH_PARAM } from './constants';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import Header from '../forms/components/header';
import styles from './PolicyFormPage.module.css';
import { featureFlagCompleteForm } from '@/services/common/config';
import { IPublicClientApplication } from '@azure/msal-browser';

interface Props {
  pca: IPublicClientApplication;
}

const PolicyFormPage: React.FC<Props> = ({ pca }) => {
  const user = pca.getActiveAccount();
  const [urlSearchParams] = useSearchParams();
  const customerId = urlSearchParams.get(CUSTOMER_ID_SEARCH_PARAM);
  const policyId = urlSearchParams.get(POLICY_ID_SEARCH_PARAM);
  const { formKey } = useParams();
  const authedFetch = useAuthenticatedFetch();

  const { data, state, triggerUpdate } = useAsync<FormContent, undefined>(
    useCallback(async () => {
      if (!formKey) {
        throw new Error('Missing form key');
      }

      const result = await APIManager.forms.getCompleteForm(
        {
          customerId: customerId ?? undefined,
          policyId: policyId ?? undefined,
          formType: formKey ?? undefined,
          apimURL: '/apim',
          apimKey: '',
          submitterEmail: user?.username,
        },
        { injectedFetch: authedFetch }
      );
      return result.formContent;
    }, [authedFetch, customerId, formKey, policyId])
  );

  useEffect(() => void triggerUpdate(undefined), []);

  if (!customerId || !formKey) {
    return;
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        {state === 'complete' ? (
          <Form
            onAction={async (action, formData) => {
              console.log(action, formData);
            }}
            options={{
              googlePlaces: {
                apiKey: featureFlagCompleteForm,
              },
            }}
            rows={data?.rows ?? []}
            actions={data?.actions ?? []}
          />
        ) : (
          <>
            <LoadingSpinner />
            <Copy element="p">Loading form...</Copy>
          </>
        )}
      </div>
    </>
  );
};

export default PolicyFormPage;
