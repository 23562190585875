import { useEffect, useRef, useState } from 'react';
import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import TextField from '@/components/shared/textField';
import Icon from '@/components/Icon';
import { ListOption, Select } from '@/components/shared/select';
import { Policy } from '@/models/document/policy';
import { Opportunity } from '@/models/document/opportunity';
import SectionDivider from '@/components/shared/sectionDivider';
import { addFileToFusion } from '@/services/document/documentService';
import { UploadData } from '@/models/document/uploadData';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import styles from './upload.module.css';
import '@/theme.module.css';
import { formatTerm } from '../../../controllers/common/utils';

export interface UploadProps {
  open: boolean;
  closeDialog: any;
  files?: FileList[];
  categoryList: string[];
  accountId: string;
  policyList: Policy[];
  opportunityList: Opportunity[];
  showAlertToast: (type: string, message: string) => void;
  refreshData: () => void;
  pca: IPublicClientApplication;
  userName: string;
  linkedOppId?: string;
}

const resetOptionListState = (prevState: ListOption[]) => {
  const optionListCopy = [...prevState];
  optionListCopy.forEach((option: ListOption) => {
    option.selected = false;
  });
  return optionListCopy;
};

export default function Upload({
  open,
  closeDialog,
  files = [],
  categoryList,
  accountId,
  policyList,
  opportunityList,
  showAlertToast,
  refreshData,
  pca,
  userName,
  linkedOppId,
}: UploadProps) {
  const fileInputRef: { current: any } = useRef<HTMLInputElement>(null);
  const [fileDisplayName, setFileDisplayName] = useState<string>('');

  const [category, setCategory] = useState<string>('');
  const [categoryOptionList, setCategoryOptionList] = useState<ListOption[]>(
    []
  );

  const [notes, setNotes] = useState<string>('');

  const [selectedOpportunities, setSelectedOpportunities] = useState<string[]>(
    []
  );
  const [opportunityOptionList, setOpportunityOptionList] = useState<
    ListOption[]
  >([]);

  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [policyOptionList, setPolicyOptionList] = useState<ListOption[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    const mappedValues = opportunityList.map(
      (opp: Opportunity) =>
        ({
          label: opp.name,
          value: opp.opportunityId,
        } as ListOption)
    );
    setOpportunityOptionList(mappedValues);
  }, [opportunityList]);

  useEffect(() => {
    const mappedValues = policyList.map(
      (policy: Policy) =>
        ({
          label: `${policy.carrier.name} (${policy.policyNumber})`,
          subtext: `Term: ${formatTerm(
            policy.effectiveDate,
            policy.expirationDate
          )} | Status: ${policy.policyStatus}`,
          value: policy.policyId,
          groupByValue: new Date(policy.effectiveDate).getFullYear(),
        } as ListOption)
    );
    setPolicyOptionList(mappedValues);
  }, [policyList]);

  useEffect(() => {
    const mappedValues = categoryList.map((category: string) => {
      return {
        label: category,
      } as ListOption;
    });
    setCategoryOptionList(mappedValues);
  }, [categoryList]);

  useEffect(() => {
    if (files && files.length && fileInputRef && fileInputRef.current) {
      fileInputRef.current.files = files;
      setFileDisplayName(fileInputRef.current.files[0].name);
    }
  }, [files]);

  const handleOpportunitySelect = (clickedOpportunity: ListOption) => {
    const selectedOppIds: string[] = [];

    const oppOptionListCopy = [...opportunityOptionList];
    oppOptionListCopy.forEach((opportunity: ListOption) => {
      if (opportunity.value === clickedOpportunity.value) {
        opportunity.selected = !opportunity.selected;
      }
      if (opportunity.selected) {
        selectedOppIds.push(opportunity.value);
      }
    });
    setOpportunityOptionList(oppOptionListCopy);
    setSelectedOpportunities(selectedOppIds);
  };

  const handlePolicySelect = (clickedPolicy: ListOption) => {
    const selectedPolicyIds: string[] = [];

    const policyOptionListCopy = [...policyOptionList];
    policyOptionListCopy.forEach((policy: ListOption) => {
      if (policy.value === clickedPolicy.value) {
        policy.selected = !policy.selected;
      }
      if (policy.selected) {
        selectedPolicyIds.push(policy.value);
      }
    });
    setPolicyOptionList(policyOptionListCopy);
    setSelectedPolicies(selectedPolicyIds);
  };

  const handleCategorySelect = (clickedCategory: ListOption) => {
    setCategory(clickedCategory.label);

    const categoryOptionListCopy = [...categoryOptionList];
    categoryOptionListCopy.forEach((category) => {
      if (category.label === clickedCategory.label) {
        category.selected = true;
      } else {
        category.selected = false;
      }
    });

    setCategoryOptionList(categoryOptionListCopy);
  };

  const save = () => {
    const user = pca.getActiveAccount();

    if (!user) {
      showAlertToast('warning', 'User not logged in.');
      return;
    }
    const data: UploadData = {
      file: fileInputRef.current.files[0],
      fileName: fileDisplayName,
      category,
      notes,
      policyList: selectedPolicies,
      opportunityList: linkedOppId ? [linkedOppId] : selectedOpportunities,
      accountId,
      userId: user.homeAccountId.split('.')[0],
      userName: userName,
    };

    setLoading(true);
    addFileToFusion(data, authedFetch)
      .then((result: string) => {
        resetThenClose();
        showAlertToast('success', `Added "${result}"`);
        refreshData();
      })
      .catch((error: string) => {
        showAlertToast('warning', 'Error adding file.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetThenClose = () => {
    setSelectedOpportunities([]);
    setOpportunityOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    setSelectedPolicies([]);
    setPolicyOptionList(resetOptionListState);

    setCategory('');
    setCategoryOptionList(resetOptionListState);

    setNotes('');
    setFileDisplayName('');

    closeDialog();
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Upload a document</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <SectionDivider title={'Properties'} theme={'crm'} />
      <div className={styles.inputContainer}>
        <label>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*, video/*, .doc, .docx, .pdf, .xml, .txt, .csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <TextField
            label={'Document name'}
            theme={'crm'}
            value={fileDisplayName}
            onChange={(value: string) => setFileDisplayName(value)}
            required={true}
          />
        </label>
        <Select
          label={'Category'}
          theme={'crm'}
          options={categoryOptionList}
          required={true}
          placeholder={'Select a category...'}
          updateValue={handleCategorySelect}
        />
        <TextField
          label={'Note'}
          theme={'crm'}
          value={notes}
          onChange={(value: string) => setNotes(value)}
          placeholder={'Add additional details here...'}
        />
      </div>
      {!linkedOppId && (
        <>
          <SectionDivider title={'Link to '} theme={'crm'} />
          <div className={styles.inputContainer}>
            <Select
              label={'Policy'}
              theme={'crm'}
              options={policyOptionList}
              placeholder={'Select an associated policy...'}
              updateValue={handlePolicySelect}
              multiple={true}
            />
            <Select
              label={'Opportunity'}
              theme={'crm'}
              options={opportunityOptionList}
              placeholder={'Select an associated opportunity...'}
              updateValue={handleOpportunitySelect}
              multiple={true}
            />
          </div>
        </>
      )}
      <div className={styles.buttonContainer}>
        <Button
          callback={save}
          type={'primary'}
          theme={'crm'}
          disabled={!fileDisplayName || !category}
          loading={loading}
        >
          Add document
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
