import {
  educationOptions,
  employmentStatusOptions,
  genderOptions,
  maritalStatusOptions,
  relationOptions,
} from '../helpers';
import type { FormPage } from '../models/formTypes';

export const coApplicant: FormPage = {
  id: 'coApplicant',
  display: 'Co-Applicant',
  icon: 'person',
  elements: [
    {
      id: 'contactDetails',
      display: 'Contact Details',
      type: 'section',
      elements: [
        {
          name: 'coApplicant.firstName',
          display: 'First name',
          type: 'text',
          placeholder: 'Enter first name...',
          validation: {
            maxLength: {
              value: 255,
              message: 'First name cannot be more than 255 characters',
            },
          },
        },
        {
          name: 'coApplicant.lastName',
          display: 'Last name',
          type: 'text',
          placeholder: 'Enter last name...',
          validation: {
            maxLength: {
              value: 255,
              message: 'Last name cannot be more than 255 characters',
            },
          },
        },
      ],
    },
    {
      id: 'additionalInfo',
      display: 'Additional Information',
      type: 'section',
      elements: [
        {
          name: 'coApplicant.dateOfBirth',
          display: 'Date of Birth',
          type: 'date',
        },
        {
          name: 'coApplicant.gender',
          display: 'Gender',
          placeholder: 'Select...',
          type: 'dropdown',
          options: genderOptions,
        },
        {
          name: 'coApplicant.maritalStatus',
          display: 'Marital status',
          placeholder: 'Select...',
          type: 'dropdown',
          options: maritalStatusOptions,
        },
        {
          name: 'coApplicant.education',
          display: 'Education',
          placeholder: 'Select...',
          type: 'dropdown',
          options: educationOptions,
        },
        {
          name: 'coApplicant.employmentStatus',
          display: 'Employment Status',
          placeholder: 'Select...',
          type: 'dropdown',
          options: employmentStatusOptions,
        },
        {
          name: 'coApplicant.industry',
          display: 'Industry',
          visibleIf: {
            fieldName: 'coApplicant.employmentStatus',
            includeList: ['Employed'],
          },
          placeholder: 'Select...',
          type: 'apidropdown',
          optionsBy: 'dropdown/industry',
        },
        {
          name: 'coApplicant.occupation',
          display: 'Occupation',
          visibleIf: {
            fieldName: 'coApplicant.employmentStatus',
            includeList: ['Employed'],
          },
          placeholder: 'Select...',
          type: 'apidropdown',
          optionsBy: 'dropdown/occ_{industry}',
        },
        {
          name: 'coApplicant.relation',
          display: 'Relation',
          placeholder: 'Select...',
          type: 'dropdown',
          options: relationOptions,
        },
      ],
    },
  ],
};
